import { NgModule } from "@angular/core";
import { S25ScheduledEmailsComponent } from "./s25.scheduled.emails.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";

@NgModule({
    declarations: [S25ScheduledEmailsComponent],
    imports: [
        S25TableModule,
        CommonModule,
        FormsModule,
        S25LoadingInlineModule,
        S25ModalModule,
        S25RichTextEditorModule,
    ],
    exports: [S25ScheduledEmailsComponent],
    providers: [S25ScheduledEmailsComponent],
})
export class S25ScheduledEmailsModule {
    constructor() {}
}
