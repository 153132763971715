import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { RuleActionListItem, RuleTreeService } from "../../services/rule.tree.service";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25RuleConditionsComponent } from "./s25.rule.conditions.component";
import { Rules } from "./s25.rule.const";

@TypeManagerDecorator("s25-ng-rule")
@Component({
    selector: "s25-ng-rule",
    template: `
        <div *ngIf="isInit" class="root-rule">
            <div class="flexRow">
                <label for="ruleName" class="ngBold">Name: </label>
                <input id="ruleName" [(ngModel)]="name" type="text" maxlength="40" class="c-input" />
            </div>

            <div class="flexRow">
                <label for="active" class="ngBold">Active: </label>
                <s25-toggle-button [(modelValue)]="active" id="active" name="active"></s25-toggle-button>
            </div>

            <s25-ng-rule-conditions
                [operator]="parsedRule.operator"
                [children]="parsedRule.children"
                [isTopLevel]="true"
                [discreteOptions]="discreteOptions"
                [customAttributes]="customAttributes"
            ></s25-ng-rule-conditions>

            <h3 class="targetsLabel">Targets</h3>

            <div class="targets">
                <s25-simple-collapse
                    *ngFor="let target of Rules.targetOptions"
                    [headerText]="
                        target.label +
                        ' (' +
                        (target.valueType.type === 'textarea'
                            ? targets[target.valueType.action][0].itemValue.length
                            : targets[target.valueType.action].length) +
                        ')'
                    "
                    [defaultCollapsed]="true"
                >
                    <div class="target">
                        <div *ngIf="target.valueType.type === 'contactRole'" class="contactRole">
                            <s25-ng-multiselect-search-criteria
                                [modelBean]="{}"
                                [selectedItems]="targets[target.valueType.action]"
                                [type]="'eventRoles'"
                                [popoverOnBody]="true"
                            ></s25-ng-multiselect-search-criteria>

                            <div
                                *ngFor="let role of targets[target.valueType.action]; let i = index"
                                class="contactRoleRow"
                            >
                                <button (click)="targets[target.valueType.action].splice(i, 1)" class="delete">
                                    <s25-ng-icon [type]="'close'" [label]="'Delete'"></s25-ng-icon>
                                </button>
                                <label>{{ role.itemName }}</label>
                                <div class="flexRow">
                                    <s25-ng-dropdown-search-criteria
                                        [type]="'contacts'"
                                        [(chosen)]="role.contact"
                                        [useChoiceForChosen]="true"
                                        [placeholder]="'Select Contact (optional)'"
                                    ></s25-ng-dropdown-search-criteria>
                                    <button
                                        class="aw-button aw-button--outline clearContact"
                                        (click)="role.contact = null"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="target.valueType.type === 'multiselectWithQuantity'"
                            class="multiselectWithQuantity"
                        >
                            <s25-ng-multiselect-search-criteria
                                [modelBean]="{}"
                                [selectedItems]="targets[target.valueType.action]"
                                [type]="target.valueType.criterion"
                                [customFilterValue]="target.valueType.filter"
                                [popoverOnBody]="true"
                                (changed)="updateQuantity($event)"
                            ></s25-ng-multiselect-search-criteria>

                            <div *ngIf="!!targets[target.valueType.action].length" class="header">Quantity</div>
                            <div
                                *ngFor="let item of targets[target.valueType.action]; let i = index"
                                class="multiselectRow"
                            >
                                <button (click)="targets[target.valueType.action].splice(i, 1)" class="delete">
                                    <s25-ng-icon [type]="'close'" [label]="'Delete'"></s25-ng-icon>
                                </button>
                                <div class="flexRow">
                                    <label>{{ item.itemName }}</label>
                                    <input
                                        type="number"
                                        [(ngModel)]="item.number"
                                        (ngModelChange)="item.itemValue = 'quantity=' + $event"
                                        [min]="1"
                                        class="c-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div *ngIf="target.valueType.type === 'dropdown'" class="flexRow">
                            <s25-ng-dropdown-search-criteria
                                [type]="target.valueType.criterion"
                                [customFilterValue]="target.valueType.filter"
                                [(chosen)]="targets[target.valueType.action][0]"
                            ></s25-ng-dropdown-search-criteria>
                            <button
                                class="aw-button aw-button--outline clearPrimaryOrg"
                                (click)="targets[target.valueType.action] = []"
                            >
                                Clear
                            </button>
                        </div>

                        <s25-ng-multiselect-search-criteria
                            *ngIf="target.valueType.type === 'multiselect'"
                            [modelBean]="{ showResult: true }"
                            [selectedItems]="targets[target.valueType.action]"
                            [type]="target.valueType.criterion"
                            [customFilterValue]="target.valueType.filter"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>

                        <div *ngIf="target.valueType.type === 'textarea'">
                            <label> {{ target.valueType.label }}: </label>
                            <textarea
                                type="text"
                                class="c-input"
                                [(ngModel)]="targets[target.valueType.action][0].itemValue"
                                [maxLength]="512"
                            ></textarea>
                        </div>
                    </div>
                </s25-simple-collapse>
            </div>
            <div class="buttons-bar">
                <button class="aw-button aw-button--primary" (click)="save()">Save</button>
                <button class="aw-button aw-button--outline" (click)="cancel()">Cancel</button>
            </div>
        </div>
    `,
    styles: `
        .flexRow,
        .multiselectRow {
            display: flex;
            gap: 0.5em;
        }

        .flexRow > :first-child {
            flex-grow: 1;
        }

        .targetsLabel {
            padding-top: 1em;
        }

        .targets {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(100%, 20em), 1fr));
            gap: 0.5em;
        }

        .target {
            text-align: center;
            padding: 0.5em;
        }

        textarea {
            width: min(100%, 500px) !important;
            min-height: 9em;
            padding: 0.5em !important;
        }

        .contactRole,
        .multiselectWithQuantity {
            display: grid;
            gap: 0.5em;
        }

        .contactRole label {
            font-weight: bold;
        }

        .contactRoleRow {
            position: relative;
            display: grid;
            gap: 0.5em;
        }

        .contactRoleRow .delete {
            position: absolute;
            top: -2px; /* Icon has 2px of blank space */
            left: -2px;
        }

        .multiselectWithQuantity .header {
            width: 5em;
            margin-left: auto;
        }

        .delete {
            border: 0;
            background: transparent;
            padding: 0;
            cursor: pointer;
        }

        .delete s25-ng-icon:hover {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            padding: 0.25em;
            box-sizing: content-box;
            margin: -0.25em;
        }

        .multiselectRow > div {
            flex-grow: 1;
        }

        .multiselectWithQuantity label {
            align-self: center;
            text-align: left;
            flex-grow: 1;
        }

        .multiselectWithQuantity input {
            width: 5em;
        }

        .buttons-bar {
            display: flex;
            gap: 0.5em;
            padding-top: 1em;
        }

        ::ng-deep s25-ng-rule .targets .simple-collapse--wrapper {
            margin: 0 !important;
        }

        ::ng-deep s25-ng-rule .targets .simple-collapse--wrapper.expanded {
            height: 100%;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }

        ::ng-deep s25-ng-rule s25-generic-dropdown .select2-choice {
            padding-left: 3px !important;
        }

        ::ng-deep s25-ng-rule s25-ng-dropdown-search-criteria .s25-item-holder {
            vertical-align: middle;
            padding-right: 0.5em;
        }

        .root-rule > .flexRow {
            align-items: center;
            padding-bottom: 1em;
            flex-wrap: wrap;
            justify-content: start;
        }

        .root-rule > .flexRow > :first-child {
            width: 4em;
            flex-grow: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleComponent implements OnInit {
    @Input() rule: Rules.Rule;

    @Output() saved = new EventEmitter<void>();
    @Output() canceled = new EventEmitter<void>();

    @ViewChild(S25RuleConditionsComponent) childRule: S25RuleConditionsComponent;

    // Template aliases
    Rules = Rules;

    isInit = false;
    parsedRule: Rules.Conditions;
    discreteOptions: Record<number, S25ItemI[]> = {};
    customAttributes: Rules.SourceItem[] = [];
    id: number;
    name: string;
    active: boolean;
    targets: Record<Rules.Action, Rules.ActionTarget[]>;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        await Promise.all([this.getDiscreteOptions(), this.getCustomAttributes()]);

        this.parsedRule = this.rule.conditions;
        this.id = this.rule.id;
        this.name = this.rule.name;
        this.active = this.rule.active;

        this.initializeTargets();

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    initializeTargets() {
        // Initialize targets
        const targets = this.rule.targets;
        for (let target of Rules.targetOptions) {
            if (targets[target.valueType.action]) continue; // Skip if already initialized
            targets[target.valueType.action] = [];
            if (target.valueType.type === "textarea") {
                targets[target.valueType.action].push({
                    itemValue: "",
                    itemTypeId: target.id,
                    itemId: target.id,
                    itemName: "",
                });
            }
        }
        this.targets = targets as typeof this.targets;
    }

    async getDiscreteOptions() {
        const options = await CustomAttributeService.getAllDiscreteOptions();
        for (let option of options)
            this.discreteOptions[option.itemId] = option.opt.map((o: string) => ({ itemId: o, itemName: o }));
    }

    async getCustomAttributes() {
        const customAttributes = await SearchCriteriaService.getCustomAttributeItems(Item.Ids.Event);
        this.customAttributes = customAttributes
            .filter((attribute) => Rules.allowedCustomAttributeTypes.has(attribute.itemTypeId))
            .map((attribute) => ({
                itemId: attribute.itemId,
                attributeType: attribute.itemTypeId,
                itemName: attribute.itemName,
            }));
    }

    async save() {
        if (!this.validate()) return;
        let { rules, values, actions } = this.getArrayRepresentation();
        const ok = !!(await RuleTreeService.putRule(this.id, this.active, rules, values, actions).catch(
            S25Util.showError,
        ));
        if (ok) this.saved.emit();
    }

    cancel() {
        this.canceled.emit();
    }

    validate() {
        let message: string;
        if (!this.name) message = "Please enter a name for this rule tree";
        if (!message) message = this.childRule.validate();
        if (!message) {
            const hasTarget = Object.entries(this.targets).some(([action, items]) => {
                const type = Rules.targetActionToTarget[action as Rules.Action].valueType.type;
                switch (type) {
                    case "textarea":
                        return !!items[0].itemValue.length;
                    case "dropdown":
                        return !!items[0];
                    case "contactRole":
                    case "multiselect":
                    case "multiselectWithQuantity":
                        return !!items.length;
                    default:
                        return false;
                }
            });
            if (!hasTarget) message = "Please configure at least one target";
        }

        if (message) alert(message);
        return !message;
    }

    getArrayRepresentation() {
        const { rules, values } = this.childRule.getArrayRepresentation(0, 1);
        rules[0].rule_cat = "form";
        rules[0].rule_name = this.name;

        const actions: RuleActionListItem[] = Object.entries(this.targets).map(([action, items]) => {
            const target = Rules.targetActionToTarget[action as Rules.Action];

            let targets = items.map((item) => ({
                target_type_id: target === Rules.target.Attributes ? target.id : item.itemTypeId || target.id,
                target_id: item.itemId,
                value: item.itemValue,
            }));
            if (target.valueType.type === "textarea") targets = targets.filter((target) => target.value); // Filter out empty textareas

            return { dummy_id: 1, action: action as Rules.Action, targets };
        });
        actions.push({
            dummy_id: 1,
            action: "populateContactRole",
            targets: this.targets.addContactRole
                .filter((role) => role.contact)
                .map((role) => ({
                    target_type_id: 3,
                    target_id: role.contact.itemId,
                    value: `contact_role_id=${role.itemId}`,
                })),
        });

        return { rules, actions, values };
    }

    updateQuantity(items: Rules.ActionTarget[]) {
        for (let item of items) {
            item.number ??= 1;
            item.itemValue ??= `quantity=${item.number}`;
        }
    }
}
