import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25RuleConditionComponent } from "./s25.rule.condition.component";
import { RuleTableItem, RuleValueListItem } from "../../services/rule.tree.service";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Rules } from "./s25.rule.const";

@TypeManagerDecorator("s25-ng-rule-conditions")
@Component({
    selector: "s25-ng-rule-conditions",
    template: `
        <div class="rule c-objectDetails c-objectDetails--borderedSection" [class.top-level]="isTopLevel">
            <div class="c-sectionHead">
                <h2>{{ this.operator | uppercase }}</h2>
            </div>
            <s25-simple-collapse [titleText]="'Nested Condition'">
                <label class="operator-label">
                    <span>Operator: </span>
                    <select [(ngModel)]="operator" class="cn-form__control">
                        <option value="and">and</option>
                        <option value="or">or</option>
                    </select>
                </label>

                <div class="conditions">
                    <ng-container *ngFor="let child of children; let i = index" [ngSwitch]="!!child.children">
                        <s25-ng-rule-conditions
                            *ngSwitchCase="true"
                            [discreteOptions]="discreteOptions"
                            [customAttributes]="customAttributes"
                            (selfDestruct)="removeCondition(i)"
                            [operator]="child.operator"
                            [children]="child.children"
                            [order]="i"
                        ></s25-ng-rule-conditions>
                        <s25-ng-rule-condition
                            *ngSwitchCase="false"
                            [discreteOptions]="discreteOptions"
                            [customAttributes]="customAttributes"
                            (selfDestruct)="removeCondition(i)"
                            [operator]="child.operator"
                            [typeId]="child.type"
                            [sourceItem]="child.sourceItem"
                            [values]="child.values"
                            [order]="i"
                        ></s25-ng-rule-condition>
                    </ng-container>
                </div>

                <div class="buttons">
                    <button
                        *ngIf="!isTopLevel"
                        (click)="selfDestruct.emit()"
                        class="aw-button aw-button--danger--outline"
                    >
                        Remove
                    </button>
                    <button (click)="addCondition(false)" class="aw-button aw-button--outline">Add Condition</button>
                    <button (click)="addCondition(true)" class="aw-button aw-button--outline">
                        Add Nested Condition
                    </button>
                </div>
            </s25-simple-collapse>
        </div>
    `,
    styles: `
        #s25.nm-party--on s25-ng-rule-conditions .c-objectDetails {
            border: 1px solid #28272c;
        }

        #s25.nm-party--on s25-ng-rule-conditions .rule:not(.top-level) > .c-sectionHead {
            border-top-right-radius: 0;
        }

        s25-ng-rule-conditions .rule:not(.top-level) {
            border-top-right-radius: 0 !important;
            border-right: 0 !important;
            margin: 1em 0;
        }

        s25-ng-rule-conditions .operator-label {
            padding-top: 0.5em;
            margin: auto 0;
        }

        s25-ng-rule-conditions .operator-label > span {
            font-size: 1.15em;
            width: 100px;
            display: inline-block;
        }

        s25-ng-rule-conditions s25-simple-collapse {
            display: block;
            padding-left: 1em;
        }

        s25-ng-rule-conditions .buttons {
            padding: 1em;
            display: flex;
            gap: 0.5em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleConditionsComponent {
    @Input() operator: Rules.Conjunction;
    @Input() children: (Rules.Conditions | Rules.Condition)[] = [];
    @Input() isTopLevel = false;
    @Input() discreteOptions: Record<number, S25ItemI[]> = {};
    @Input() customAttributes: Rules.SourceItem[];
    @Input() order: number; // Used to retrieve sort order when saving

    @Output() selfDestruct = new EventEmitter<void>();

    @ViewChildren(S25RuleConditionsComponent) childConditionsComponents: QueryList<S25RuleConditionsComponent>;
    @ViewChildren(S25RuleConditionComponent) childConditionComponents: QueryList<S25RuleConditionComponent>;

    constructor(private changeDetector: ChangeDetectorRef) {}

    addCondition(nested: boolean) {
        let condition: Rules.Conditions | Rules.Condition;
        if (nested) condition = { operator: "and", children: [] };
        else condition = { type: Rules.type.CustomAttribute.id, sourceItem: null, operator: null, values: [] };
        this.children.push(condition);
        this.changeDetector.detectChanges();
    }

    removeCondition(index: number) {
        this.children.splice(index, 1);
        this.changeDetector.detectChanges();
    }

    validate() {
        if (this.isTopLevel && !this.children.length) return "Please add at least one rule";
        const childComponents = [
            ...this.childConditionsComponents.toArray(),
            ...this.childConditionComponents.toArray(),
        ];
        let messages: string[] = childComponents.map((condition) => condition.validate()).filter((msg) => msg);
        return messages[0];
    }

    getArrayRepresentation(parentId: number, id: number) {
        const childComponents = [
            ...this.childConditionsComponents.toArray(),
            ...this.childConditionComponents.toArray(),
        ];
        childComponents.sort((a, b) => a.order - b.order); // We have to sort into display order

        const rules: RuleTableItem[] = [
            {
                dummy_id: id,
                operator: this.operator,
                rule_type: "bool",
            },
        ];
        if (!this.isTopLevel) rules[0].parent_dummy_id = parentId;
        const values: RuleValueListItem[] = [];

        let nextId = id + 1;
        for (let child of childComponents) {
            const { rules: childRules, values: childValues } = child.getArrayRepresentation(id, nextId);
            rules.push.apply(rules, childRules);
            values.push.apply(values, childValues);
            nextId += childRules.length;
        }

        return { rules, values };
    }
}
