import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Bind } from "../../decorators/bind.decorator";
import { RuleTableItem, RuleValueListItem } from "../../services/rule.tree.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { Rules } from "./s25.rule.const";
import { PreferenceService } from "../../services/preference.service";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;

@TypeManagerDecorator("s25-ng-rule-condition")
@Component({
    selector: "s25-ng-rule-condition",
    template: `
        <div *ngIf="isInit" class="dropdown-container c-objectDetails c-objectDetails--borderedSection">
            <div class="c-sectionHead d-flex">
                <p class="header-label">
                    <span class="bold">{{ type.label }} </span>
                    <span>{{ sourceItem?.itemName }} </span>
                </p>
            </div>
            <s25-simple-collapse [titleText]="'Condition'">
                <div class="condition">
                    <label>
                        <span class="label">Source: </span>
                        <select [(ngModel)]="source" (ngModelChange)="onSourceChange()" class="cn-form__control">
                            <option *ngFor="let source of Rules.sources" [ngValue]="source">
                                {{ source.label }}
                            </option>
                        </select>
                    </label>

                    <label *ngIf="source.isGroup">
                        <span class="label"></span>
                        <select [(ngModel)]="type" (ngModelChange)="onTypeChange()" class="cn-form__control">
                            <option *ngFor="let t of Rules.groups[source.id]" [ngValue]="t">
                                {{ t.label }}
                            </option>
                        </select>
                    </label>

                    <label *ngIf="type.sourceItems">
                        <span class="label">{{ type.sourceItems.label }}: </span>
                        <s25-generic-dropdown
                            [items]="type.sourceItems.options"
                            [(chosen)]="sourceItem"
                        ></s25-generic-dropdown>
                    </label>

                    <label *ngIf="type === Rules.type.CustomAttribute">
                        <span class="label">Attribute: </span>
                        <s25-generic-dropdown
                            [items]="customAttributes"
                            [(chosen)]="sourceItem"
                            (chosenChange)="onAttributeChange()"
                            [searchEnabled]="true"
                            [placeholder]="'Select Custom Attribute'"
                        ></s25-generic-dropdown>
                    </label>

                    <label *ngIf="valueType.type !== 'attribute'">
                        <span class="label">Operator: </span>
                        <select [(ngModel)]="operator" class="cn-form__control">
                            <option *ngFor="let operator of Rules.operators[valueType.type]" [ngValue]="operator">
                                {{ operator }}
                            </option>
                        </select>
                    </label>

                    <ng-container *ngIf="valueType?.type === 'multiselect'">
                        <s25-ng-multiselect-search-criteria
                            *s25-ng-trigger-rerender="multiselectModel"
                            [type]="valueType.criterion"
                            [customFilterValue]="valueType.filter"
                            [modelBean]="multiselectModel"
                            [selectedItems]="values"
                            [popoverOnBody]="true"
                            [popoverPlacement]="'right'"
                        ></s25-ng-multiselect-search-criteria>
                    </ng-container>

                    <div *ngIf="valueType.type !== 'multiselect' && valueType.type !== 'attribute'">
                        <div *ngFor="let value of values; let i = index; trackBy: trackByIndex" class="valueWrapper">
                            <label>
                                <span class="label">Value: </span>
                            </label>
                            <div [ngSwitch]="valueType.type" class="value">
                                <s25-editable-boolean
                                    *ngSwitchCase="'boolean'"
                                    [model]="{ data: $any(values[i]), falseLabel: 'No', trueLabel: 'Yes' }"
                                    (modelValueChange)="values[i] = $event"
                                ></s25-editable-boolean>
                                <s25-ng-editable-text
                                    *ngSwitchCase="'text'"
                                    [(val)]="values[i]"
                                    [max]="valueType.maxLength"
                                    [alwaysEditing]="true"
                                ></s25-ng-editable-text>
                                <ng-container *ngSwitchCase="'discrete'">
                                    <s25-generic-dropdown
                                        *ngIf="operator !== 'contains'"
                                        [items]="discreteOptions[sourceItem.itemId]"
                                        [(chosen)]="values[i]"
                                        [placeholder]="'Select a value'"
                                    ></s25-generic-dropdown>
                                    <s25-ng-editable-text
                                        *ngIf="operator === 'contains'"
                                        [val]="$any(values[i])?.itemId"
                                        (valChange)="values[i] = $any({ itemId: $event })"
                                        [alwaysEditing]="true"
                                    ></s25-ng-editable-text>
                                </ng-container>
                                <s25-ng-editable-number
                                    *ngSwitchCase="'number'"
                                    [(val)]="values[i]"
                                    [type]="valueType.flavor"
                                    [alwaysEditing]="true"
                                ></s25-ng-editable-number>
                                <s25-ng-editable-textarea
                                    *ngSwitchCase="'textarea'"
                                    [(val)]="values[i]"
                                    [max]="valueType.maxLength"
                                    [alwaysEditing]="true"
                                ></s25-ng-editable-textarea>
                                <s25-ng-editable-date *ngSwitchCase="'date'" [(val)]="values[i]"></s25-ng-editable-date>
                                <s25-ng-editable-date-time
                                    *ngSwitchCase="'datetime'"
                                    [(val)]="values[i]"
                                ></s25-ng-editable-date-time>
                                <s25-timepicker *ngSwitchCase="'time'" [(modelValue)]="values[i]"></s25-timepicker>
                                <div *ngSwitchCase="'occurrenceDate'" class="between">
                                    <s25-ng-editable-date-time
                                        [val]="values[i]?.[0]"
                                        [allowEmpty]="false"
                                        (valChange)="values[i] = [$event, values[i]?.[1]]"
                                    ></s25-ng-editable-date-time>
                                    <ng-container *ngIf="operator === 'between'">
                                        <p>and</p>
                                        <s25-ng-editable-date-time
                                            [val]="values[i]?.[1]"
                                            [allowEmpty]="false"
                                            (valChange)="values[i] = [values[i]?.[0], $event]"
                                        ></s25-ng-editable-date-time>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'occurrenceTime'" class="between">
                                    <s25-timepicker
                                        [modelValue]="values[i]?.[0]"
                                        (modelValueChange)="values[i] = [$event, values[i]?.[1]]"
                                    ></s25-timepicker>
                                    <ng-container *ngIf="operator === 'between'">
                                        <p>and</p>
                                        <s25-timepicker
                                            [modelValue]="values[i]?.[1]"
                                            (modelValueChange)="values[i] = [values[i]?.[0], $event]"
                                        ></s25-timepicker>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'occurrenceDow'">
                                    <div class="checkboxes">
                                        <s25-ng-checkbox *ngFor="let dow of dows" [(modelValue)]="values[i][dow.value]">
                                            {{ dow.label }}
                                        </s25-ng-checkbox>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'search'">
                                    <s25-ng-search-dropdown
                                        [itemTypeId]="valueType.itemType"
                                        [allowNonQueryId]="false"
                                        [initialSearch]="
                                            values[i] && {
                                                itemTypeId: valueType.itemType,
                                                property: 'itemId',
                                                value: $any(values[i]).itemId,
                                            }
                                        "
                                        (chosenChange)="values[i] = $any($event)"
                                        [onlyPublic]="valueType.public"
                                    ></s25-ng-search-dropdown>
                                </div>
                                <div *ngSwitchCase="'checkbox'">
                                    <s25-ng-checkbox
                                        [modelValue]="!!values[i]"
                                        (modelValueChange)="values[i] = $event"
                                    ></s25-ng-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="c-margin-top--single buttons">
                        <button (click)="selfDestruct.emit()" class="aw-button aw-button--danger--outline rule-remove">
                            Remove
                        </button>

                        <button
                            *ngIf="
                                (operator === 'in' || operator === 'not in') &&
                                type === Rules.type.CustomAttribute &&
                                valueType.type !== 'multiselect'
                            "
                            (click)="addValue()"
                            class="aw-button aw-button--outline"
                        >
                            Add Another Value
                        </button>
                    </div>
                </div>
            </s25-simple-collapse>
        </div>
    `,
    styles: `
        ::ng-deep #s25.nm-party--on s25-ng-rule-condition .c-objectDetails {
            border: 1px solid #28272c;
        }

        ::ng-deep #s25.nm-party--on s25-ng-rule-condition .c-sectionHead {
            border-top-right-radius: 0 !important;
        }

        .dropdown-container {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: 0 !important;
            margin: 1em 0;
        }

        .condition {
            max-width: 500px;
            padding: 1em;
        }

        label {
            display: flex;
            margin-bottom: 1em;
        }

        label > .label {
            width: 100px;
            margin: auto;
        }

        label > .label + * {
            flex-grow: 1;
        }

        .header-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.5em 3em 0.5em 1em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        .between p {
            padding: 0.5em 0;
        }

        .valueWrapper {
            display: flex;
        }

        .valueWrapper label {
            margin: 0;
        }

        ::ng-deep s25-ng-rule-condition .valueWrapper s25-editable-boolean label {
            margin: 0 -1em 0 1em;
        }

        .valueWrapper .value {
            flex: 1;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-number input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-text input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-textarea textarea {
            width: 100% !important;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-date-time > div {
            display: grid;
            gap: 0.5rem;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker input {
            padding: 0 8px !important;
        }

        ::ng-deep s25-ng-rule-condition s25-generic-dropdown .select2-choice {
            padding-left: 3px !important;
        }

        .checkboxes {
            gap: 0.5rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(100%, 4em), 1fr));
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleConditionComponent implements OnInit {
    @Input() typeId: Rules.TypeId = Rules.type.CustomAttribute.id;
    @Input() sourceItem?: Rules.SourceItem;
    @Input() operator: Rules.Operator;
    @Input() values: Rules.ConditionValue[] = [];
    @Input() discreteOptions: Record<number, S25ItemI[]> = {};
    @Input() customAttributes: Rules.SourceItem[];
    @Input() order: number; // Used to retrieve sort order when saving

    @Output() selfDestruct = new EventEmitter<void>();

    // Template aliases
    Rules = Rules;

    isInit = false;
    source: Rules.Source;
    type: Rules.Type;
    valueType: Rules.ValueType;
    multiselectModel: MultiselectModelI;
    dows = [
        { value: "M" as const, label: "Mon" as const },
        { value: "T" as const, label: "Tue" as const },
        { value: "W" as const, label: "Wed" as const },
        { value: "R" as const, label: "Thu" as const },
        { value: "F" as const, label: "Fri" as const },
        { value: "S" as const, label: "Sat" as const },
        { value: "U" as const, label: "Sun" as const },
    ];

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        const firstDow = (await PreferenceService.getPreferences(["CalendarView"])).CalendarView.value || 7;
        S25Util.array.rotate(this.dows, 8 - firstDow);

        this.source = Rules.typeIdToSource[this.typeId];
        this.type = Rules.typeIdToType[this.typeId];
        this.setValueType();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    onSourceChange() {
        if ("isGroup" in this.source) {
            this.type = Rules.groups[this.source.id][0];
        } else {
            this.type = Rules.typeIdToType[this.source.id];
        }
        this.onTypeChange();
    }

    onTypeChange() {
        this.typeId = this.type.id;
        this.setDefaultSourceItem();
        this.setValueType();
        this.setDefaultOperator();
        this.resetValues();
        this.changeDetector.detectChanges();
    }

    onAttributeChange() {
        this.setValueType();
        this.setDefaultOperator();
        this.resetValues();
        this.changeDetector.detectChanges();
    }

    @Bind
    onItemChooserChange() {
        const selected = this.multiselectModel.selectedItems;
        this.values = selected.map(({ itemId, itemName }: Rules.ItemValue) => ({ itemId, itemName }));
        this.changeDetector.detectChanges();
    }

    setValueType() {
        // If source is custom attribute, select valueType based on the selected attribute
        if (this.type === Rules.type.CustomAttribute && this.sourceItem) {
            if (
                this.sourceItem.attributeType === Rules.attributeType.Text &&
                this.discreteOptions[this.sourceItem.itemId]
            ) {
                this.valueType = Rules.valueType.Discrete;
                // Discrete values need to be mapped to a dropdown format
                this.values = this.values.map((str: string) => ({ itemId: str, itemName: str }));
            } else {
                this.valueType =
                    Rules.attributeValueType[this.sourceItem.attributeType as keyof typeof Rules.attributeValueType];
            }
        } else {
            this.valueType = this.type.valueType;
        }

        if (this.valueType.type === "multiselect") {
            this.multiselectModel = {
                showResult: true,
                onChange: this.onItemChooserChange,
                title: this.valueType.label,
            };
        }
    }

    resetValues() {
        if (this.valueType.type === "multiselect") this.values = [];
        else if (this.valueType.type === "occurrenceDow") this.values = [{} as Record<DowChar, boolean>];
        else this.values = [null];
    }

    setDefaultSourceItem() {
        if ("sourceItems" in this.type && this.type.sourceItems.options.length) {
            this.sourceItem = this.type.sourceItems.options[0];
        } else {
            this.sourceItem = null;
        }
    }

    setDefaultOperator() {
        this.operator = Rules.operators[this.valueType.type][0];
    }

    addValue() {
        this.values.push(null);
    }

    validate() {
        if (this.typeId === Rules.type.CustomAttribute.id && !this.sourceItem) return "Please pick a custom attribute";
        else if (!this.values.filter((val) => val !== null).length) return "Please fill out conditions for all rules";
    }

    // This is linting as unused for me, but it is used in s25.rule.conditions.component.ts
    getArrayRepresentation(parentId: number, id: number) {
        const rule: RuleTableItem = {
            dummy_id: id,
            parent_dummy_id: parentId,
            operator: this.operator,
            rule_type: "comp",
            source_item_type_id: this.typeId,
        };
        if ("hasSourceItem" in this.type && this.type.hasSourceItem) rule.source_item_id = this.sourceItem.itemId;
        const values: RuleValueListItem[] = this.values.map((value) => {
            switch (this.valueType.type) {
                case "multiselect":
                case "search":
                case "discrete":
                    return { dummy_id: id, value: (value as Rules.ItemValue).itemId };
                case "date":
                    return { dummy_id: id, value: S25Util.date.toS25ISODateStr(value) };
                case "time":
                    return { dummy_id: id, value: S25Util.date.toS25ISOTimeStr(value) };
                case "datetime":
                    return { dummy_id: id, value: S25Util.date.toS25ISODateTimeStr(value) };
                case "occurrenceDate":
                    return {
                        dummy_id: id,
                        value: (value as Date[])
                            .filter((_) => _)
                            .map((date) => S25Util.date.toS25ISODateTimeStr(date))
                            .sort()
                            .join(),
                    };
                case "occurrenceTime":
                    return {
                        dummy_id: id,
                        value: (value as Date[])
                            .filter((_) => _)
                            .map((date) => S25Util.date.dateToHours(date))
                            .sort((a, b) => a - b)
                            .join(),
                    };
                case "occurrenceDow":
                    return {
                        dummy_id: id,
                        value: Object.entries(value as Record<DowChar, boolean>)
                            .filter(([_, checked]) => checked)
                            .map(([dow]) => dow)
                            .join(""),
                    };
                default:
                    return { dummy_id: id, value };
            }
        });

        return { rules: [rule], values };
    }

    trackByIndex(index: number) {
        return index;
    }
}
