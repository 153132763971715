import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { S25SpringCacheComponent } from "./s25.spring.cache.component";

@NgModule({
    declarations: [S25SpringCacheComponent],
    imports: [CommonModule],
    providers: [S25SpringCacheComponent],
})
export class S25SpringCacheModule {
    constructor() {}
}
