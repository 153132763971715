import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { Table } from "../s25-table/Table";
import { ScheduledEmail, ScheduledEmailService } from "../../services/scheduled.email.service";
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import Cell = Table.Cell;
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { EmailDoc, RecipientDoc } from "../../services/email.service";
import { TelemetryService } from "../../services/telemetry.service";

export interface EditingEmail extends ScheduledEmail {
    scheduleType?: "daysFromEventStart" | "daysFromEventEnd" | "daysFromNow";
    scheduleDays?: number;
    emailDoc?: EmailDoc;
    tos?: string;
    ccs?: string;
    bccs?: string;

    [index: string]: any;
}

@TypeManagerDecorator("s25-ng-scheduled-emails")
@Component({
    selector: "s25-ng-scheduled-emails",
    template: `
        <ng-container *ngIf="init">
            <s25-ng-table
                [caption]="'Scheduled Emails'"
                [unlimitedWidth]="true"
                [columnSortable]="false"
                [dataSource]="dataSource"
                [hasTotalRowCount]="true"
                [hasRefresh]="true"
            >
            </s25-ng-table>

            <s25-ng-modal #scheduledEmailModal [title]="'Scheduled Email'" [size]="'md'">
                <ng-template #s25ModalBody>
                    <label class="ngBlock c-margin-bottom--single"
                        ><span>Schedule Type:&nbsp;</span>
                        <select [(ngModel)]="editingEmail.scheduleType" class="cn-form__control">
                            <option value="daysFromEventStart">Days from Event Start</option>
                            <option value="daysFromEventEnd">Days from Event End</option>
                            <option value="daysFromNow">Days from Now</option>
                        </select>
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span>Schedule Offset Days:&nbsp;</span>
                        <input type="number" class="c-input" [(ngModel)]="editingEmail.scheduleDays" />
                    </label>

                    <div class="c-margin-bottom--single delimiterNote">
                        <svg class="c-svgIcon" role="img" aria-hidden="true">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                            ></use>
                        </svg>
                        <span class="c-margin-left--quarter"
                            >Note: email addresses should be delimited by ; or a new line</span
                        >
                    </div>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span class="textareaLabel">To Recipients:&nbsp;</span>
                        <textarea class="cn-form__control" rows="2" cols="30" [(ngModel)]="editingEmail.tos"></textarea>
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span class="textareaLabel">CC Recipients:&nbsp;</span>
                        <textarea class="cn-form__control" rows="2" cols="30" [(ngModel)]="editingEmail.ccs"></textarea>
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span class="textareaLabel">BCC Recipients:&nbsp;</span>
                        <textarea
                            class="cn-form__control"
                            rows="2"
                            cols="30"
                            [(ngModel)]="editingEmail.bccs"
                        ></textarea>
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span>Subject:&nbsp;</span>
                        <input type="text" class="c-input" [(ngModel)]="editingEmail.emailDoc.email.mail.subject" />
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        ><span>Body:&nbsp;</span>
                        <s25-ng-rich-text-editor
                            [(modelValue)]="editingEmail.emailDoc.email.mail.body.text"
                        ></s25-ng-rich-text-editor>
                    </label>

                    <div class="ngFloatRight">
                        <button class="aw-button aw-button--primary c-margin-right--single" (click)="saveEdit()">
                            Save
                        </button>
                        <button class="aw-button aw-button--outline" (click)="cancelEdit()">Cancel</button>
                    </div>
                </ng-template>
            </s25-ng-modal>
        </ng-container>
    `,
    styles: `
        .textareaLabel {
            vertical-align: top;
        }

        label > span {
            display: inline-block;
            width: 25%;
        }

        .c-svgIcon {
            background-color: transparent;
            color: #2573a7;
        }

        .delimiterNote {
            background-color: #eee;
            padding: 4px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ScheduledEmailsComponent implements OnInit {
    @Input() itemTypeId: number;
    @Input() itemId: number;

    @ViewChild(S25TableComponent) table: S25TableComponent;
    @ViewChild("scheduledEmailModal") scheduledEmailModal: S25ModalComponent;

    init = false;
    dataSource: Table.Unpaginated;
    dateFormat: string;
    emailLookup = new Map<number, ScheduledEmail>();
    editingEmail: EditingEmail;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit(): void {
        TelemetryService.sendWithSub("EventDetails", "ScheduledEmails", "Visit");

        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "type",
                    header: "Type",
                    sortable: false,
                },
                {
                    id: "send_by",
                    header: "Send By",
                    sortable: false,
                },
                {
                    id: "subject",
                    header: "Subject",
                    sortable: false,
                },
                GenericTableButtonComponent.Column("Edit", this.edit, "outline"),
                GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline"),
            ],
        };

        S25Util.all({
            dateFormat: UserprefService.getS25Dateformat(),
        }).then((resp: any) => {
            this.dateFormat = resp.dateFormat;
            this.init = true;
            this.cd.detectChanges();
        });
    }

    getRows = async (query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> => {
        let items = await ScheduledEmailService.getScheduledEmails(this.itemTypeId, this.itemId);
        items = items || [];

        let rows: Table.Row[] = [];

        jSith.forEach(items, (_: number, item: ScheduledEmail) => {
            this.emailLookup.set(item.scheduledEmailId, item);
            const cells: Table.Row["cells"] = {};

            for (let i = 0; i < this.dataSource.columns.length; i++) {
                let column = this.dataSource.columns[i];
                let cell: Cell = {};

                switch (column.id) {
                    case "type":
                        if (S25Util.isDefined(item.daysFromEventStart)) {
                            cell.text = "Days from Event Start";
                        } else if (S25Util.isDefined(item.daysFromEventEnd)) {
                            cell.text = "Days from Event End";
                        } else if (S25Util.isDefined(item.daysFromNow)) {
                            cell.text = "Days from Now";
                        }
                        break;
                    case "send_by":
                        cell.text = S25Datefilter.transform(item.sendByUtc, this.dateFormat);
                        cell.sortValue = item.sendByUtc;
                        break;
                    case "subject":
                        try {
                            let emailDoc: EmailDoc = JSON.parse(item.emailDocStr);
                            cell.text = emailDoc?.email?.mail?.subject ?? "";
                        } catch (ex) {
                            cell.text = "";
                        }
                        break;
                    default:
                        continue;
                }

                cells[column.id] = cell;
            }

            rows.push({
                id: item.scheduledEmailId,
                name: "" + item.scheduledEmailId,
                cells: cells,
            });
        });

        return {
            rows: rows,
            totalRows: items.length,
        };
    };

    delete = async (row: Table.Row, instance: GenericTableButtonComponent) => {
        instance.isLoading = true;
        try {
            await ScheduledEmailService.deleteScheduledEmail(row.id as number);
            await this.table.refresh(true);
        } catch (e) {
            S25Util.showError(e);
        }
        instance.isLoading = false;
    };

    edit = async (row: Table.Row) => {
        let scheduledEmailId = row.id as number;
        let editingEmail = S25Util.deepCopy(this.emailLookup.get(scheduledEmailId)) as EditingEmail;
        editingEmail.emailDoc = JSON.parse(editingEmail.emailDocStr);
        editingEmail.emailDoc.email.mail.recipient ??= [];
        editingEmail.emailDoc.email.mail.body.text ??= "";

        editingEmail.tos =
            editingEmail.emailDoc.email.mail.recipient
                .filter((r) => !!r.to)
                .map((r) => r.to)
                .join("; ") || "";
        editingEmail.ccs =
            editingEmail.emailDoc.email.mail.recipient
                .filter((r) => !!r.cc)
                .map((r) => r.cc)
                .join("; ") || "";
        editingEmail.bccs =
            editingEmail.emailDoc.email.mail.recipient
                .filter((r) => !!r.bcc)
                .map((r) => r.bcc)
                .join("; ") || "";

        ["daysFromEventStart", "daysFromEventEnd", "daysFromNow"].forEach((val) => {
            if (S25Util.isDefined(editingEmail[val]) && !editingEmail.scheduleType) {
                editingEmail.scheduleType = val as "daysFromEventStart" | "daysFromEventEnd" | "daysFromNow";
                editingEmail.scheduleDays = editingEmail[val] as number;
            }
        });

        this.editingEmail = editingEmail;
        this.scheduledEmailModal.changeDetector.detectChanges();
        this.cd.detectChanges();
        await this.scheduledEmailModal.open();
        this.editingEmail = null;
    };

    cancelEdit = async () => {
        await this.scheduledEmailModal.close();
    };

    saveEdit = async () => {
        let tos = this.stringToEmailList(this.editingEmail.tos).map((s) => {
            return { to: s } as RecipientDoc;
        });
        let ccs = this.stringToEmailList(this.editingEmail.ccs).map((s) => {
            return { cc: s } as RecipientDoc;
        });
        let bccs = this.stringToEmailList(this.editingEmail.bccs).map((s) => {
            return { bcc: s } as RecipientDoc;
        });
        this.editingEmail.emailDoc.email.mail.recipient = [].concat(tos, ccs, bccs);

        let scheduledEmail: ScheduledEmail = {
            objectId: this.itemId,
            objectTypeId: this.itemTypeId,
            scenarioId: this.editingEmail.scenarioId,
            templateId: this.editingEmail.templateId,
            manualUuid: this.editingEmail.manualUuid,
            emailDocStr: JSON.stringify(this.editingEmail.emailDoc),
        };

        scheduledEmail.daysFromEventStart =
            this.editingEmail.scheduleType === "daysFromEventStart" ? this.editingEmail.scheduleDays : undefined;
        scheduledEmail.daysFromEventEnd =
            this.editingEmail.scheduleType === "daysFromEventEnd" ? this.editingEmail.scheduleDays : undefined;
        scheduledEmail.daysFromNow =
            this.editingEmail.scheduleType === "daysFromNow" ? this.editingEmail.scheduleDays : undefined;

        await ScheduledEmailService.updateScheduledEmail(scheduledEmail);

        this.scheduledEmailModal.changeDetector.detectChanges();
        this.cd.detectChanges();
        await this.scheduledEmailModal.close();
        await this.table.refresh(true);
    };

    stringToEmailList(str: string) {
        return S25Util.array.unique(((str && str.split(/[;\n]/)) || []).map(S25Util.trim).filter(S25Util.isTruthy));
    }
}
