import { LooseAutocomplete, ValueOf } from "../../pojo/Util";
import { Item } from "../../pojo/Item";
import { RuleActionTarget } from "../../services/rule.tree.service";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";

export namespace Rules {
    import DowChar = EventSummary.DowChar;
    export type Conjunction = "and" | "or";
    export type Operator = "=" | "<" | "<=" | ">=" | ">" | "in" | "not in" | "contains" | "between";
    export type ValueType = ValueOf<typeof valueType>;
    export type TypeId = Type["id"];
    export type Type = ValueOf<typeof type>;
    export type SourceId = Source["id"];
    export type Source = ValueOf<typeof source>;
    export type AttributeType = ValueOf<typeof attributeType>;
    export type AdditionalTimeId = ValueOf<typeof additionalTime>["itemId"];
    export type Target = ValueOf<typeof target>;
    export type TargetId = Target["id"];
    export type Action = Target["valueType"]["action"] | "populateContactRole";

    export type Rule = {
        conditions: Conditions;
        name: string;
        category: LooseAutocomplete<"form">;
        id: number;
        active: boolean;
        targets: Partial<Record<Action, Rules.ActionTarget[]>>;
    };

    export type Conditions = {
        operator: Conjunction;
        children: (Conditions | Condition)[];
    };

    export type Condition = {
        type: TypeId;
        sourceItem?: SourceItem;
        operator: Operator;
        values: ConditionValue[];
    };

    export type SourceItem = {
        itemId: number;
        attributeType?: AttributeType;
        itemName?: string;
    };

    export type ConditionValue =
        | boolean
        | string
        | number
        | Date
        | ItemValue
        | Date[]
        | number[]
        | Record<DowChar, boolean>;

    export type ItemValue = {
        itemId: number | string;
        itemName: string;
    };

    export type ActionTarget = RuleActionTarget & {
        number?: number; // Currently only for resources
        contact?: ActionTarget; // Only for contact roles
    };

    export const valueType = {
        CustomAttribute: { type: "attribute" },
        Location: { type: "multiselect", criterion: "locations", filter: "", label: "Locations" },
        LocationSearch: { type: "search", itemType: Item.Ids.Location, public: true },
        LocationLayout: { type: "multiselect", criterion: "locationLayouts", filter: "", label: "Layouts" },
        Resource: { type: "multiselect", criterion: "resources", filter: "", label: "Resources" },
        ResourceSearch: { type: "search", itemType: Item.Ids.Resource, public: true },
        Organization: { type: "multiselect", criterion: "organizations", filter: "", label: "Organizations" },
        OrganizationSearch: { type: "search", itemType: Item.Ids.Organization, public: true },
        SecurityGroup: { type: "multiselect", criterion: "securityGroups", filter: "", label: "Security Groups" },
        EventType: { type: "multiselect", criterion: "eventTypes", filter: "", label: "Event Types" },
        EventCategory: { type: "multiselect", criterion: "eventCategories", filter: "", label: "Categories" },
        Requirement: {
            type: "multiselect",
            criterion: "eventRequirements",
            filter: "requirement_type=6",
            label: "Requirements",
        },
        CalendarRequirement: {
            type: "multiselect",
            criterion: "eventRequirements",
            filter: "requirement_type=7",
            label: "Calendar Requirements",
        },
        Integer: { type: "number", flavor: "integer" },
        Float: { type: "number", flavor: "float" },
        Boolean: { type: "boolean" },
        Text80: { type: "text", maxLength: 80 },
        Text250: { type: "text", maxLength: 250 },
        Text10000: { type: "textarea", maxLength: 10_000 },
        Discrete: { type: "discrete" },
        Date: { type: "date" },
        Time: { type: "time" },
        DateTime: { type: "datetime" },
        OccurrenceDate: { type: "occurrenceDate" },
        OccurrenceTime: { type: "occurrenceTime" },
        OccurrenceDow: { type: "occurrenceDow" },
        State: { type: "multiselect", criterion: "eventStates", filter: "", label: "States" },
        Checkbox: { type: "checkbox" },
    } as const;

    export const operators: Record<ValueType["type"], Operator[]> = {
        attribute: [],
        multiselect: ["in", "not in"],
        search: ["in", "not in"],
        number: ["=", "<", "<=", ">=", ">"],
        boolean: ["="],
        text: ["=", "in", "not in", "contains"],
        textarea: ["=", "in", "not in", "contains"],
        discrete: ["=", "in", "not in", "contains"],
        date: ["=", "<", "<=", ">=", ">"],
        time: ["=", "<", "<=", ">=", ">"],
        datetime: ["=", "<", "<=", ">=", ">"],
        occurrenceDate: ["between", "=", "<", "<=", ">=", ">"],
        occurrenceTime: ["between", "=", "<", "<=", ">=", ">"],
        occurrenceDow: ["="],
        checkbox: ["="],
    };

    export const attributeType = {
        DateTime: "E",
        Date: "D",
        Time: "T",
        Boolean: "B",
        Organization: 2,
        Location: 4,
        Resource: 6,
        Float: "F",
        Integer: "N",
        Text: "S",
        LongText: "X",
        URL: "R",
    } as const;

    export const attributeValueType = {
        E: valueType.DateTime,
        D: valueType.Date,
        T: valueType.Time,
        B: valueType.Boolean,
        2: valueType.Organization,
        4: valueType.Location,
        6: valueType.Resource,
        F: valueType.Float,
        N: valueType.Integer,
        S: valueType.Text80,
        X: valueType.Text10000,
        R: valueType.Text250,
    } as const;

    export const additionalTime = {
        None: { itemId: 0, itemName: "None" },
        PrePost: { itemId: 1, itemName: "Pre & Post" },
        SetupTakedown: { itemId: 2, itemName: "Setup & Takedown" },
    } as const;

    export const ifChanged = {
        Yes: { itemId: 0, itemName: "Yes" },
        No: { itemId: 1, itemName: "No" },
    };

    export const sourceItems = {
        additionalTime: {
            label: "Include",
            options: [additionalTime.None, additionalTime.PrePost, additionalTime.SetupTakedown],
        },
        ifChanged: {
            label: "Only when changed",
            options: [ifChanged.No, ifChanged.Yes],
        },
    } as const;

    export const type = {
        CustomAttribute: {
            id: 11,
            label: "Custom Attribute",
            valueType: valueType.CustomAttribute,
            hasSourceItem: true,
        },
        Location: { id: 4, label: "Items", valueType: valueType.Location },
        // Location layouts have ID 430 in search criteria
        LocationLayout: { id: 430, label: "Layout", valueType: valueType.LocationLayout },
        Resource: { id: 6, label: "Items", valueType: valueType.Resource },
        Organization: { id: 2, label: "Items", valueType: valueType.Organization },
        // Searches have ID X05 in search criteria
        LocationSearch: { id: 405, label: "Search", valueType: valueType.LocationSearch },
        ResourceSearch: { id: 605, label: "Search", valueType: valueType.ResourceSearch },
        OrganizationSearch: { id: 205, label: "Search", valueType: valueType.OrganizationSearch },
        SecurityGroup: { id: 99, label: "Security Group", valueType: valueType.SecurityGroup },
        EventType: { id: 19, label: "Event Type", valueType: valueType.EventType },
        EventCategory: { id: 120, label: "Event Category", valueType: valueType.EventCategory },
        // Requirements really have an ID of 9, but we need them to be separate here.
        // Normal requirements have req type 6, and calendar requirements have typ 7, hence 96 and 97
        // Actually, elsewhere requirements have ID 7, but 9 is used in rules for whatever reason.
        Requirement: { id: 96, label: "Requirement", valueType: valueType.Requirement },
        CalendarRequirement: { id: 97, label: "Calendar Requirement", valueType: valueType.CalendarRequirement },
        // In search criteria Expected Head Count and Registered Head Count are both id 141, so let's use 1410 and 1411 here
        ExpectedHeadcount: { id: 1410, label: "Expected Head Count", valueType: valueType.Integer },
        RegisteredHeadcount: { id: 1411, label: "Registered Head Count", valueType: valueType.Integer },
        // In search criteria event details are all id 100, so let's append to that
        Date: {
            id: 1000,
            label: "Date",
            valueType: valueType.OccurrenceDate,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        TimeOfDay: {
            id: 1001,
            label: "Time of Day",
            valueType: valueType.OccurrenceTime,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        DayOfWeek: {
            id: 1002,
            label: "Day of Week",
            valueType: valueType.OccurrenceDow,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        // In search criteria event state is 101
        EventState: {
            id: 101,
            label: "State",
            valueType: valueType.State,
            hasSourceItem: true,
            sourceItems: sourceItems.ifChanged,
        },
        Affirmation: {
            id: -1,
            label: "Affirmation",
            valueType: valueType.Checkbox,
        },
    } as const;

    export const source = {
        CustomAttribute: { id: type.CustomAttribute.id, label: type.CustomAttribute.label },
        Location: { id: Item.Ids.Location, label: "Location", isGroup: true },
        Resource: { id: Item.Ids.Resource, label: "Resource", isGroup: true },
        Organization: { id: Item.Ids.Organization, label: "Organization", isGroup: true },
        SecurityGroup: { id: type.SecurityGroup.id, label: type.SecurityGroup.label },
        EventType: { id: type.EventType.id, label: type.EventType.label },
        EventCategory: { id: type.EventCategory.id, label: type.EventCategory.label },
        Requirement: { id: type.Requirement.id, label: type.Requirement.label },
        CalendarRequirement: { id: type.CalendarRequirement.id, label: type.CalendarRequirement.label },
        EventDetails: { id: Item.Ids.Event, label: "Event Details", isGroup: true },
        Affirmation: { id: -1, label: type.Affirmation.label },
    } as const;

    export const sources = Object.values(source);

    export const groups = {
        [Item.Ids.Event]: [
            type.Date,
            type.DayOfWeek,
            type.TimeOfDay,
            type.ExpectedHeadcount,
            type.RegisteredHeadcount,
            type.EventState,
        ],
        [Item.Ids.Location]: [type.Location, type.LocationSearch, type.LocationLayout],
        [Item.Ids.Resource]: [type.Resource, type.ResourceSearch],
        [Item.Ids.Organization]: [type.Organization, type.OrganizationSearch],
    };

    export const typeIdToSource = {} as Record<TypeId, Source>;
    for (let entry of Object.entries(source)) {
        const source1 = entry[1];
        if ("isGroup" in source1 && source1.isGroup) {
            for (let type1 of groups[source1.id]) typeIdToSource[type1.id] = source1;
        } else {
            const key = source1.id as TypeId;
            typeIdToSource[key] = source1;
        }
    }

    export const typeIdToType = {} as Record<TypeId, Type>;
    for (let type1 of Object.values(type)) typeIdToType[type1.id] = type1;

    export const allowedCustomAttributeTypes: Set<AttributeType> = new Set([
        attributeType.Text,
        attributeType.LongText,
        attributeType.URL,
        attributeType.DateTime,
        attributeType.Date,
        attributeType.Time,
        attributeType.Integer,
        attributeType.Float,
        attributeType.Boolean,
        attributeType.Organization,
        attributeType.Location,
        attributeType.Resource,
    ]);

    export const actionType = {
        Attribute: {
            type: "multiselect",
            action: "addCustAtrb",
            criterion: "eventCustomAttributes",
            filter: "",
            label: "Custom Attributes",
        },
        Resource: {
            type: "multiselectWithQuantity",
            action: "addResource",
            criterion: "resources",
            filter: "",
            label: "Resources",
        },
        RecommendedResource: {
            type: "multiselect",
            action: "addRecommendedResource",
            criterion: "resources",
            filter: "",
            label: "Recommended Resources",
        },
        Requirement: {
            type: "multiselect",
            action: "addRequirement",
            criterion: "eventRequirements",
            filter: "requirement_type=6",
            label: "Requirements",
        },
        CalendarRequirement: {
            type: "multiselect",
            action: "addCalRequirement",
            criterion: "eventRequirements",
            filter: "requirement_type=7",
            label: "Calendar Requirements",
        },
        Category: {
            type: "multiselect",
            action: "addCategory",
            criterion: "eventCategories",
            filter: "",
            label: "Calendar Requirements",
        },
        ContactRole: { type: "contactRole", action: "addContactRole" },
        PrimaryOrganization: {
            type: "dropdown",
            action: "setPrimaryOrganization",
            criterion: "organizations",
            filter: "",
            label: "Primary Organization",
        },
        AdditionalOrganization: {
            type: "multiselect",
            action: "addAdditionalOrganization",
            criterion: "organizations",
            filter: "",
            label: "Additional Organizations",
        },
        Alert: { type: "textarea", action: "alertUser", label: "Alert" },
        Notify: { type: "textarea", action: "notifyUser", label: "Notification" },
    } as const;

    export const target = {
        Attributes: { id: 11, label: "Add Custom Attributes", valueType: actionType.Attribute },
        Resources: { id: Item.Ids.Resource, label: "Add Resources", valueType: actionType.Resource },
        RecommendedResources: { id: 6, label: "Add Recommended Resources", valueType: actionType.RecommendedResource },
        Requirements: { id: 9, label: "Add Requirements", valueType: actionType.Requirement },
        CalendarRequirements: { id: 9, label: "Add Calendar Requirements", valueType: actionType.CalendarRequirement },
        Categories: { id: 120, label: "Add Categories", valueType: actionType.Category },
        ContactRole: { id: 16, label: "Add Contact Role", valueType: actionType.ContactRole },
        PrimaryOrganization: {
            id: Item.Ids.Organization,
            label: "Set Primary Organization",
            valueType: actionType.PrimaryOrganization,
        },
        AdditionalOrganizations: {
            id: Item.Ids.Organization,
            label: "Add Additional Organizations",
            valueType: actionType.AdditionalOrganization,
        },
        Alert: { id: -1, label: "Alert User", valueType: actionType.Alert },
        Notify: { id: -2, label: "Notify User", valueType: actionType.Notify },
    } as const;

    export const targetOptions = Object.values(target);

    export const targetActionToTarget = {} as Record<Action, Target>;
    for (let t of Object.values(target)) targetActionToTarget[t.valueType.action] = t;

    export const itemTypes: Set<TypeId> = new Set(
        Object.values(type)
            .filter((type) => type.valueType.type === "multiselect")
            .map((type) => type.id),
    );

    export const itemAttributes: Set<AttributeType> = new Set(
        Object.values(attributeType).filter((type) => attributeValueType[type].type === "multiselect"),
    );

    export const asyncValueTypes: Set<ValueType["type"]> = new Set(["search"] as const);
    export const asyncTypes: Set<TypeId> = new Set(
        Object.values(type)
            .filter((type) => asyncValueTypes.has(type.valueType.type))
            .map((type) => type.id),
    );
}
