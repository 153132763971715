import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ContactService } from "../../services/contact.service";
import { SpringCacheService } from "./spring.cache.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-spring-cache")
@Component({
    selector: "s25-spring-cache",
    template: `<div *ngIf="init">
        <button (click)="onClick()" class="aw-button aw-button--primary c-margin-bottom--quarter">
            Clear Spring Cache
        </button>
        <p>{{ successMessage }}</p>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SpringCacheComponent implements OnInit {
    self = this;
    successMessage: string;
    init: boolean = false;
    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.elementRef.nativeElement.angBridge = this;
        ContactService.getCurrentUsername().then((username: string) => {
            if (username.toLowerCase() == "service25") {
                this.init = true;
                this.cd.detectChanges();
            }
        });
    }

    onClick(): void {
        SpringCacheService.clearCache().then(
            (resp) => {
                this.successMessage = resp.results.message;
                this.cd.detectChanges();
            },
            (err) => {
                alert("Clearing spring cache failed");
            },
        );
    }
}
